import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const api = axios.create({
  baseURL: 'https://api.viewmax.cc/api/v1/node/all_nodes',
  headers: {
    'Authorization': 'Bearer 26A2xj9FLmebJDUbz4GesLWl6jCb2jGe2iyUfaDi4VKjMhCfbChtAII7iwVBJrW3'
  }
});

class Allnodes extends Component {
  state = {
    nodes_list: []
    }

  
  constructor() {
   super();
    
    api.get('').then(res => {
      const nodes_list = res.data;
      this.setState({ nodes_list });
      // console.log(res.data)
    })
  }

  render() {    
    return ( 
      <>
        <div  style= {{background:" -webkit-linear-gradient(to right, #8360c3, #2ebf91)",   background:" linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden", height: "100vh"}}>
          <h1 style={{color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%"}}>All Nodes</h1>
          <table id="table">
            <tbody>
              <tr id="table_header">
                <th>No</th>
                <th>NODE</th>
                <th>COUNTRY</th>
                <th>STATE</th>
                <th>LGA</th>
                <th>GROUP ID</th>
                <th>GROUP NAME</th>
                <th>CONTINENT</th>
              </tr>

              {this.state.nodes_list.map(nodes_list =>
              <tr id="table_body" key={nodes_list.id}>
                <td>{nodes_list.id}</td>
                <td><Link to={`/node/${nodes_list.node_id}`}>{nodes_list.node_id}</Link></td>
                <td>{nodes_list.country}</td>
                <td>{nodes_list.state}</td>
                <td>{nodes_list.lga}</td>
                <td>{nodes_list.group_id}</td>
                <td>{nodes_list.group_name}</td>
                <td>{nodes_list.continent}</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </>
      )
    }
}


export default Allnodes;