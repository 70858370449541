import React, { Component } from 'react';
//some css for ACCESS is also in App.css
class Access extends Component {


  getFormattedDateTime = (date) => {
    // Add 60 minutes to the current date
    date.setMinutes(date.getMinutes() + 60);
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  };

  
  handleSetAccessSystem = () => {
    const nodeIdInput = document.getElementById("nodeInput");
    const nodeIdSelect = document.getElementById("nodeSelect");
    let nodeId;

    if (nodeIdInput.value !== "") {
      nodeId = nodeIdInput.value;
    } else if (nodeIdSelect.value !== "") {
      nodeId = nodeIdSelect.value;
    } else {
      alert("Please enter or select a node ID");
      return;
    }
    
  if (nodeIdInput.value !== "") {
    nodeId = nodeIdInput.value;
  } else if (nodeIdSelect.value !== "") {
    nodeId = nodeIdSelect.value;
  } else {
    alert("Please enter or select a node ID");
    return;
  }
    // Create a JSON object with the data for the first set button
    const data = {
      "node_ids": nodeId,
      "title": "f1720.mp4",
      "showtime": this.getFormattedDateTime(new Date())
    };

      // Send the data to the API using POST request
      fetch('https://api.viewmax.cc/api/v1/node/remote_access', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 26A2xj9FLmebJDUbz4GesLWl6jCb2jGe2iyUfaDi4VKjMhCfbChtAII7iwVBJrW3'
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
          // Additional actions upon successful data submission
          console.log("Access activated for " + nodeId);
          alert("Access activated for " + nodeId);
        } else {
          console.error("Failed to send data for " + nodeId);
        }
      })
      .catch(error => {
        console.error("Error:", error);
      });
};


  handleDisableSystemAccess = async () => {


    const nodeIdInput = document.getElementById("nodeInputDisableSystem");
    const nodeIdSelect = document.getElementById("nodeInputDisableSystemSelect");
    let nodeId;

    if (nodeIdInput.value !== "") {
      nodeId = nodeIdInput.value;
    } else if (nodeIdSelect.value !== "") {
      nodeId = nodeIdSelect.value;
    } else {
      alert("Please enter or select a node ID");
      return;
    }
    
    // Create a JSON object with the data for the second set button
    const data = {
      "wifi_mode": "update"
    };

    try {
      // Send the data to the API using POST request
      const response = await fetch(`https://api.viewmax.cc/api/v1/node/status/${nodeId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 26A2xj9FLmebJDUbz4GesLWl6jCb2jGe2iyUfaDi4VKjMhCfbChtAII7iwVBJrW3'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        alert(nodeId + " is set to (update)");
        // Additional actions upon successful data submission
      } else {
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  handleCheckSystemStatus = async () => {
    const nodeIdInput = document.getElementById("nodeInputCheckSystemStatus");
    const nodeIdSelect = document.getElementById("nodeInputCheckSystemStatusSelect");
    let nodeId;

    if (nodeIdInput.value !== "") {
      nodeId = nodeIdInput.value;
    } else if (nodeIdSelect.value !== "") {
      nodeId = nodeIdSelect.value;
    } else {
      alert("Please enter or select a node ID");
      return;
    }
    try {
      const token = "26A2xj9FLmebJDUbz4GesLWl6jCb2jGe2iyUfaDi4VKjMhCfbChtAII7iwVBJrW3"; // Replace "your_token_here" with your actual authorization token
      const response = await fetch(`https://api.viewmax.cc/api/v1/node/status/${nodeId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log(nodeId);
      if (response.ok) {
        const data = await response.json();
        const wifiMode = data.wifi_mode;
        alert(nodeId + " set to " +wifiMode);
        // Now you can use the wifiMode variable as needed
      } else {
        console.error('Failed to fetch system status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  


  


  render() {
    return (
      <>
        <div style={{ background: "-webkit-linear-gradient(to right, #8360c3, #2ebf91)", background: "linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden"}}>
          <h1 style={{ color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%" }}>Access</h1>
          <div className="access_container">


          <div className='access_card'>
            <p style={{ fontSize: "20px", marginBottom: "40px" }}>Access System <br />(In 10 minutes)</p>
            <input id="nodeInput" type="text" placeholder="Enter Node ID" style={{ borderRadius: "5px", padding: "0px 10px", border: "none", height: "35px", marginBottom: "5px" }} />
            <div>
              <select id="nodeSelect" style={{ borderRadius: "5px", padding: "5px 10px", border: "none", height: "35px", marginBottom: "5px" }}>
                <option value="">Select Node Type</option>
                <option value="10011">10011</option>
                <option value="10012">10012</option>
              </select>
            </div>
            <div>
              <button onClick={this.handleSetAccessSystem} style={{ backgroundColor: "rgba(39, 144, 168, 0.4)", fontSize: "18px", marginTop:"20px", border: "none", padding: "5px 10px", borderRadius: "5px" }}>
                Set
              </button>
            </div>
          </div>

            <div className='access_card'>
              <p style={{ fontSize: "20px", marginBottom: "40px" }}>Disable Access System <br />(Set to UPDATE)</p>
              <input id="nodeInputDisableSystem" type="text" placeholder="Enter Node ID" style={{ borderRadius: "5px", padding: "0px 10px", border: "none", height: "35px", marginBottom: "5px" }} />
              <div>
              <select id="nodeInputDisableSystemSelect" style={{ borderRadius: "5px", padding: "5px 10px", border: "none", height: "35px", marginBottom: "5px" }}>
                <option value="">Select Node Type</option>
                <option value="10011">10011</option>
                <option value="10012">10012</option>
              </select>
            </div>
              <div>
                <button onClick={this.handleDisableSystemAccess} style={{ backgroundColor: "rgba(39, 144, 168, 0.4)", fontSize: "18px", marginTop:"20px", border: "none", padding: "5px 10px", borderRadius: "5px" }}>
                  Set
                </button>
                
              </div>
            </div>

            <div className='access_card'>
              <p style={{ fontSize: "20px", marginBottom: "40px" }}>Check System Status</p>
              <input id="nodeInputCheckSystemStatus"  type="text" placeholder="Enter Node ID" style={{ borderRadius: "5px", padding: "0px 10px", border: "none", height: "35px", marginBottom: "5px" }} />
              <div>
              <select id="nodeInputCheckSystemStatusSelect" style={{ borderRadius: "5px", padding: "5px 10px", border: "none", height: "35px", marginBottom: "5px" }}>
                <option value="">Select Node Type</option>
                <option value="10011">10011</option>
                <option value="10012">10012</option>
              </select>
            </div>
              <div>
                <button onClick={this.handleCheckSystemStatus} style={{ backgroundColor: "rgba(39, 144, 168, 0.4)", fontSize: "18px", marginTop:"20px", border: "none", padding: "5px 10px", borderRadius: "5px" }}>
                  Set
                </button>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}
export default Access;
