import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from './dashboard';
import Allnodes from './allnodes';
import Node from './node';
import Schedule from './schedule';
import Showtimes from './showtimes';
import Register from './register';
import Upload from './upload';
import Access from './access';

const Main = () => (
    <Switch>
        <Route exact path='/' component={Dashboard} />
        <Route exact path='/allnodes' component={Allnodes} />
        <Route path='/node/:node_find' component={Node} />
        <Route exact path='/schedule' component={Schedule} />
        <Route exact path='/access' component={Access} />
        <Route exact path='/showtimes' component={Showtimes} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/upload' component={Upload} />
    </Switch>
)

export default Main;