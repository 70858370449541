import React, {Component} from 'react';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import Main from './components/main.js';

class App extends Component {
  render() {    
    return (
      <>
      <Main/>
      </>
    )
  }
}
export default App;