import React, { Component, useState } from 'react';
import axios from 'axios';
import { DateTimePicker} from "@material-ui/pickers";

var dayjs = require('dayjs')

function Scheduler(props){
  const [selectedDate, handleDateChange] = useState(new Date("2021-01-01T00:00:00.000Z"));
  var dd = '01:00'
  var un = dayjs(`${selectedDate}+${dd}`).unix()
  console.log(un)

  
  return(
    <>
    <div  style= {{background:" -webkit-linear-gradient(to right, #8360c3, #2ebf91)",   background:" linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden", height: "100vh"}}>
             <h1 style={{color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%"}}>Schedule Videos</h1>
             <div class="schedule_box">
               {/* <div>TimeBox</div> */}
               <div id="schedule_input">
                 <form>
                   <input type="text" placeholder="Movie Title" defaultValue="" onChange=""/>
                   <input type="text" placeholder="Nodes(10001-10002-10003)" defaultValue="" onChange=""/>
                   {/* <input type="text" placeholder="Showtime" defaultValue="" onChange=""/> */}
                  <DateTimePicker
                      autoOk="false"
                      disablePast="true"
                      InputProps={{disableUnderline: true,}}
                      variant="inline"
                      helperText=""
                      // label="Showtime"
                      size ="small"
                      value={selectedDate}
                      format="yyy/MM/dd hh:mm a"
                      onChange={handleDateChange}
                    /> 
                    <input id="submit_button" type="Submit" defaultValue="Apply"/>
 
                 </form>
               </div>
             </div>
           </div>
      
    </>
  );
}
export default Scheduler;

// class Schedule extends Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       startDate: ("2021-01-01T00:00:00+0000Z"),
//       unixDate: '',
      
//       };
      
//     this.handleChange = this.handleChange.bind(this);
//     this.onFormSubmit = this.onFormSubmit.bind(this);
//   }
  
//   handleChange(date,dd){
//     this.setState({
//       // startDate: date
//       dd: '3600',
//       startDate: date,
//       // startDate: dayjs(`${date}`).toISOString(),
      
//       unixDate: dayjs(`${date}`).unix(),
//     })

//     console.log(this.state.startDate)
//     console.log(this.state.unixDate)
//   }

//   onFormSubmit(e) {
//     e.preventDefault();
//     console.log(this.state.startDate)
//   }

//     render() { 
//       return ( 
//         <>
//           <div  style= {{background:" -webkit-linear-gradient(to right, #8360c3, #2ebf91)",   background:" linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden", height: "100vh"}}>
//             <h1 style={{color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%"}}>Schedule Videos</h1>
//             <div class="schedule_box">
//               <div id="schedule_input">
//                 <form onSubmit={ this.state.startDate }>
//                   <input type="text" placeholder="Movie Title" defaultValue="" onChange=""/>
//                   <input type="text" placeholder="Nodes(10001-10002-10003)" defaultValue="" onChange=""/>
//                   {/* <input type="text" placeholder="Showtime" defaultValue="" onChange=""/> */}
//                   <DateTimePicker
//                       autoOk="false"
//                       disablePast="true"
//                       InputProps={{disableUnderline: true,}}
//                       variant="inline"
//                       helperText=""
//                       // label="Showtime"
//                       size ="small"
//                       selected={this.state.startDate}
//                       value={this.state.startDate}
//                       format="yyy/MM/dd hh:mm a"
//                       onChange={this.handleChange}
//                       name="startDate"
//                     /> 
//                   <input id="submit_button" type="Submit" defaultValue="Apply"/>
                  
//                 </form>
//               </div>
//             </div>
//           </div>
//         </>
//       )
//     }
//   }
// export default Schedule;
