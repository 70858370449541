import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom'

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import App from './App'; 

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
      </MuiPickersUtilsProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

