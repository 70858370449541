import React, { Component } from 'react';


class Upload extends Component {
    render() {    
      return (
          <>
            <h1>Upload</h1>
          </>
      )
     }
}
export default Upload;
