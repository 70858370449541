import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Dashboard extends Component {
    render() {    
      return (
          <>
            <div  style= {{background:" -webkit-linear-gradient(to right, #8360c3, #2ebf91)",   background:" linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden", height: "100vh"}}>
              <h1 style={{color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%"}}>Viewmax Dashboard</h1>
              <div id="cards_box">
                <Link to="/allnodes"><div className="card"><p className="card_text">All Nodes</p></div></Link>
                <Link to="/node/search"><div className="card"><p className="card_text">Node</p></div></Link>
                <Link to="/schedule"><div className="card"><p className="card_text">Schedule</p></div></Link>
                <Link to="/access"><div className="card"><p className="card_text">Access</p></div></Link>
                <Link to="/showtimes"><div className="card"><p className="card_text">Showtimes</p></div></Link>
                <Link to="/register"><div className="card"><p className="card_text">Register</p></div></Link>
                <Link to="/upload"><div className="card"><p className="card_text">Upload</p></div></Link>
              </div>
            </div>
          </>
      )
     }
}

export default Dashboard;
