import React, { Component } from 'react';
import axios from 'axios';


const api = axios.create({
  baseURL: `https://api.viewmax.cc/api/v1/node`,
  headers: {
    'Authorization': 'Bearer 26A2xj9FLmebJDUbz4GesLWl6jCb2jGe2iyUfaDi4VKjMhCfbChtAII7iwVBJrW3'
  }
})

class Node extends Component { 
  state = {
  nodes_list: [],
  inputValue: '',
  }
  
  constructor() {
  super();
  
  }
componentDidMount(){
  api.get(`/${this.props.match.params.node_find}`).then(res => {
    const nodes_list = res.data;
    this.setState({ nodes_list });
    console.log({nodes_list})
  })
}

  handleInputvalue = event => {
    this.setState({
      inputValue: event.target.value
    })
  }

  handleSumbit = event =>{
    // alert('${this.state.inputvalue}')
    event.preventDefault()
    api.get(`/${this.state.inputValue}`).then(res => {
      const nodes_list = res.data;
      this.setState({ nodes_list });
      console.log({nodes_list})
    })
  }

  render() {    
    return ( 
      <>
        <div  style= {{background:" -webkit-linear-gradient(to right, #8360c3, #2ebf91)",   background:" linear-gradient(to right, #8360c3, #2ebf91)", overflow: "hidden", height: "100vh"}}>
          {/* <h1 style={{color: "#fff", textAlign: "center", fontFamily: "Helvetica", fontSize: "40px", marginTop: "5%"}}>Node</h1> */}
          <div id="nodes_box">
            <form onSubmit={this.handleSumbit}>
              <input type="text" placeholder="Find Node" defaultValue={this.state.inputValue} onChange={this.handleInputvalue}/>
              <input id="submit_button" type="submit" defaultValue="Submit" />
            </form>  
            
            {this.state.nodes_list.map(nodes_list =>
              <div key={nodes_list.id}>
              <div id="node_search_window">
              <div id="node_search_holder"> 
                <ul class="node_search_content" id="node_search_content1">
                  <p id = "node_name">Node: {nodes_list.node_id}</p>
                  <li>Continent:{nodes_list.continent}</li>
                  <li>Country: {nodes_list.country}</li>
                  <li>State: {nodes_list.state}</li>
                  <li>LGA/Province: {nodes_list.lga}</li>
                  <li>GroupID: {nodes_list.group_id}</li>
                  <li>Group Name: {nodes_list.group_name}</li>
                </ul>  
                <ul class="node_search_content" id="node_search_content2">
                  <p id = "node_status">
                    Status:
                    {nodes_list.online_status}
                  </p>
                  <li>Trasferring: {nodes_list.transferring}
                   
                  </li>
                  <li>Pending Transfer: {nodes_list.pending_transfer}
                    
                  </li>
                  <li>On device: {nodes_list.on_device}
                  
                  </li> 
                  <li>Software Version: {nodes_list.software_version}
                  
                  </li> 
                </ul>  
               
              </div>
              </div>
            </div>
            )}
            
          </div>
        </div>
      </>
      )
  }
}
export default Node;
