import React, { Component } from 'react';


class Register extends Component {
    render() {    
      return (
          <>
            <h1>Register</h1>
          </>
      )
     }
}
export default Register;
