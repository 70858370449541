import React, { Component } from 'react';


class Showtimes extends Component {
    render() {    
      return (
          <>
            <h1>Showtimes</h1>
          </>
      )
     }
}
export default Showtimes;
